define('threejs-in-ember/router', ['exports', 'ember', 'threejs-in-ember/config/environment'], function (exports, _ember, _threejsInEmberConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _threejsInEmberConfigEnvironment['default'].locationType,
    rootURL: _threejsInEmberConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('cube');
    this.route('sphere');
  });

  exports['default'] = Router;
});