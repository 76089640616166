define('threejs-in-ember/routes/sphere', ['exports', 'ember', 'threejs-in-ember/threejs/sphere', 'threejs-in-ember/mixins/render-into-application-template'], function (exports, _ember, _threejsInEmberThreejsSphere, _threejsInEmberMixinsRenderIntoApplicationTemplate) {
  exports['default'] = _ember['default'].Route.extend(_threejsInEmberMixinsRenderIntoApplicationTemplate['default'], {
    model: function model() {
      return {
        // http://eoimages.gsfc.nasa.gov/images/imagerecords/73000/73580/world.topo.bathy.200401.3x5400x2700.jpg
        textureUrl: '/earth.png',
        shapeName: 'sphere',
        constructor: _threejsInEmberThreejsSphere['default']
      };
    }
  });
});