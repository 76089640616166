define('threejs-in-ember/components/three-container', ['exports', 'ember', 'rsvp', 'ember-concurrency', 'npm:three', 'threejs-in-ember/ember-stringify', 'threejs-in-ember/threejs/cube', 'threejs-in-ember/threejs/sphere', 'threejs-in-ember/threejs/rotator'], function (exports, _ember, _rsvp, _emberConcurrency, _npmThree, _threejsInEmberEmberStringify, _threejsInEmberThreejsCube, _threejsInEmberThreejsSphere, _threejsInEmberThreejsRotator) {

  var CAMERA_FOV = 75;
  var CAMERA_DISTANCE = 5;
  var CAMERA_NEAR_PLANE = 0.1;
  var CAMERA_FAR_PLANE = 1000.0;
  var ROTATION_DELTA = 0.005;

  exports['default'] = _ember['default'].Component.extend({
    webgl: _ember['default'].inject.service(),
    vertexShader: _threejsInEmberEmberStringify['default']['vertex.glsl'],
    fragmentShader: _threejsInEmberEmberStringify['default']['fragment.glsl'],

    rotator: _ember['default'].computed(function () {
      return new _threejsInEmberThreejsRotator['default'](this.get('element'));
    }),

    camera: _ember['default'].computed(function () {
      var dimensions = this.get('dimensions');

      var camera = new _npmThree['default'].PerspectiveCamera(CAMERA_FOV, dimensions.width / dimensions.height, CAMERA_NEAR_PLANE, CAMERA_FAR_PLANE);

      camera.position.z = CAMERA_DISTANCE;
      return camera;
    }),

    canvasShouldResize: _ember['default'].computed(function () {
      var canvas = this.get('webgl').get('renderer').domElement;
      var dimensions = this.get('dimensions');
      return dimensions.width !== canvas.width || dimensions.height !== canvas.height;
    }).volatile(),

    dimensions: _ember['default'].computed(function () {
      var element = this.get('element');
      var displayWidth = element.clientWidth;
      var displayHeight = element.clientHeight;
      return { width: displayWidth, height: displayHeight };
    }).volatile(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('changeShapeTask').perform().then(function () {
        _this.addCanvasToElement();
      })['catch'](function (e) {
        console.warn(e);
      });
    },

    loadTexturePromise: function loadTexturePromise(url) {
      var loader = new _npmThree['default'].TextureLoader();
      return new _rsvp['default'].Promise(function (resolve) {
        loader.load(url, function (texture) {
          resolve(texture);
        });
      });
    },

    changeShapeTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var shapeData;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            shapeData = this.get('shapeData');
            context$1$0.next = 3;
            return this.get('loadTextureTask').perform(shapeData.textureUrl);

          case 3:
            this.startAnimation();

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).restartable(),

    loadTextureTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(url) {
      var texture;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.loadTexturePromise(url);

          case 2:
            texture = context$1$0.sent;

            this.set('texture', texture);

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    animateTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this2 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            /*jshint noyield:true */
            this.rotate(ROTATION_DELTA, ROTATION_DELTA);
            this.draw();
            requestAnimationFrame(function () {
              _this2.get('animateTask').perform();
            });

          case 3:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    addCanvasToElement: function addCanvasToElement() {
      var glRenderer = this.get('webgl').get('renderer');
      this.get('element').appendChild(glRenderer.domElement);
    },

    createShape: function createShape() {
      var Shape = this.get('shapeData').constructor;
      var shape = new Shape(this.vertexShader, this.fragmentShader, this.get('texture'));
      this.set('shape', shape);
    },

    addShapeToScene: function addShapeToScene() {
      this.set('scene', new _npmThree['default'].Scene());
      this.get('scene').add(this.get('shape').mesh);
    },

    startAnimation: function startAnimation() {
      this.createShape();
      this.addShapeToScene();
      this.get('animateTask').perform();
    },

    draw: function draw() {
      this.updateShapeProperties();
      this.get('shape').updateUniforms();
      this.resizeCanvas();
      var glRenderer = this.get('webgl').get('renderer');
      glRenderer.render(this.get('scene'), this.get('camera'));
    },

    touchStart: function touchStart() {
      this.set('rotating', true);
    },

    touchMove: function touchMove(event) {
      this.handleUserRotation(event);
      event.preventDefault();
    },

    touchEnd: function touchEnd() {
      this.set('rotating', false);
      this.get('rotator').reset();
    },

    mouseMove: function mouseMove(event) {
      this.touchMove(event);
    },

    mouseUp: function mouseUp() {
      this.touchEnd();
    },

    mouseDown: function mouseDown() {
      this.touchStart();
    },

    handleUserRotation: function handleUserRotation(event) {
      if (!this.get('rotating')) {
        return;
      }

      var _get$rotationDeltas = this.get('rotator').rotationDeltas(event);

      var x = _get$rotationDeltas.x;
      var y = _get$rotationDeltas.y;

      this.rotate(x, y);
    },

    rotate: function rotate(x, y) {
      var shape = this.get('shape');
      shape.rotation.x += y;
      shape.rotation.y += x;
    },

    updateShapeProperties: function updateShapeProperties() {
      this.updateScale();
      this.updatePartyMode();
    },

    updatePartyMode: function updatePartyMode() {
      var partyMode = this.get('partyMode');
      this.get('shape').partyMode = partyMode;
    },

    updateScale: function updateScale() {
      var scale = this.get('scale') || 1.0;
      this.get('shape').scale(scale);
    },

    resizeCanvas: function resizeCanvas() {
      if (!this.get('canvasShouldResize')) {
        return;
      }
      var glRenderer = this.get('webgl').get('renderer');
      var camera = this.get('camera');
      var dimensions = this.get('dimensions');

      glRenderer.setSize(dimensions.width, dimensions.height);
      camera.aspect = dimensions.width / dimensions.height;
      camera.updateProjectionMatrix();
    }
  });
});
/*exported Cube, Sphere */

/*jshint noyield:true */