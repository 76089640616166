define('threejs-in-ember/mixins/render-into-application-template', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    renderTemplate: function renderTemplate(controller, model) {
      var appController = this.controllerFor('application');
      this.render('application', {
        model: model,
        controller: appController
      });
    }
  });
});