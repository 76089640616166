define('threejs-in-ember/controllers/application', ['exports', 'ember'], function (exports, _ember) {

  var SHAPES = ['cube', 'sphere'];

  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['scale', 'partyMode'],

    isPartyMode: _ember['default'].computed('partyMode', function () {
      return !!parseInt(this.get('partyMode'));
    }),

    nextShapeName: function nextShapeName(currentShapeName) {
      return SHAPES[(SHAPES.indexOf(currentShapeName) + 1) % SHAPES.length];
    },

    actions: {
      updateScale: function updateScale(event) {
        this.set('scale', event.target.value);
      },

      updatePartyMode: function updatePartyMode(checked) {
        var partyMode = checked ? 1 : 0;
        this.set('partyMode', partyMode);
      },

      nextShape: function nextShape() {
        var currentShapeName = this.get('model').shapeName;
        var nextShapeName = this.nextShapeName(currentShapeName);
        this.transitionToRoute(nextShapeName);
      }
    }
  });
});