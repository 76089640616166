define('threejs-in-ember/routes/cube', ['exports', 'ember', 'threejs-in-ember/threejs/cube', 'threejs-in-ember/mixins/render-into-application-template'], function (exports, _ember, _threejsInEmberThreejsCube, _threejsInEmberMixinsRenderIntoApplicationTemplate) {
  exports['default'] = _ember['default'].Route.extend(_threejsInEmberMixinsRenderIntoApplicationTemplate['default'], {
    model: function model() {
      return {
        // http://emberjs.com/logos/
        textureUrl: '/ember-logo.png',
        shapeName: 'cube',
        constructor: _threejsInEmberThreejsCube['default']
      };
    }
  });
});