define("threejs-in-ember/threejs/rotator", ["exports"], function (exports) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  var _default = (function () {
    function _default(element) {
      _classCallCheck(this, _default);

      this.element = element;
    }

    _createClass(_default, [{
      key: "mousePressed",
      value: function mousePressed(event) {
        // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/which
        return !!event.buttons || !!event.which;
      }
    }, {
      key: "touchPressed",
      value: function touchPressed(event) {
        return !!event.touches;
      }
    }, {
      key: "rotationDeltas",
      value: function rotationDeltas(event) {
        var newCoordinates = this.normalizedCoordinates(event, this.element);
        if (!this.currentCoordinates) {
          this.currentCoordinates = newCoordinates;
        }
        var deltas = this.coordinateDeltas(newCoordinates);
        this.currentCoordinates = newCoordinates;
        return deltas;
      }
    }, {
      key: "coordinateDeltas",
      value: function coordinateDeltas(newCoordinates) {
        var x = newCoordinates.x - this.currentCoordinates.x;
        var y = newCoordinates.y - this.currentCoordinates.y;
        return { x: x, y: y };
      }
    }, {
      key: "normalizedCoordinates",
      value: function normalizedCoordinates(event) {
        var coordinates = this.coordinatesFromEvent(event);

        return {
          x: coordinates.x / this.dimensions.width,
          y: coordinates.y / this.dimensions.height
        };
      }
    }, {
      key: "coordinatesFromEvent",
      value: function coordinatesFromEvent(event) {
        var coordinates = { x: null, y: null };

        if (this.mousePressed(event)) {
          coordinates = this.mouseCoordinatesFromEvent(event);
        } else if (this.touchPressed(event)) {
          coordinates = this.touchCoordinatesFromEvent(event);
        }

        return coordinates;
      }
    }, {
      key: "mouseCoordinatesFromEvent",
      value: function mouseCoordinatesFromEvent(event) {
        return { x: event.clientX, y: event.clientY };
      }
    }, {
      key: "touchCoordinatesFromEvent",
      value: function touchCoordinatesFromEvent(event) {
        return { x: event.touches[0].clientX, y: event.touches[0].clientY };
      }
    }, {
      key: "reset",
      value: function reset() {
        this.currentCoordinates = null;
      }
    }, {
      key: "dimensions",
      get: function get() {
        if (!this.element) {
          return { width: null, height: null };
        }
        return { width: this.element.clientWidth, height: this.element.clientHeight };
      }
    }]);

    return _default;
  })();

  exports["default"] = _default;
});