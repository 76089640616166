define('threejs-in-ember/threejs/shape', ['exports', 'npm:three'], function (exports, _npmThree) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var _default = (function () {
    function _default(vertexShader, fragmentShader, texture) {
      _classCallCheck(this, _default);

      this.vertexShader = vertexShader;
      this.fragmentShader = fragmentShader;
      this.texture = texture;
      this.clock = new _npmThree['default'].Clock();
      this.partyMode = 0;
    }

    _createClass(_default, [{
      key: 'scale',
      value: function scale(_scale) {
        this.mesh.scale.set(_scale, _scale, _scale);
      }
    }, {
      key: 'updateUniforms',
      value: function updateUniforms() {
        this.mesh.material.uniforms.uTime.value = this.time;
        this.mesh.material.uniforms.uPartyMode.value = this.partyMode;
      }
    }, {
      key: 'mesh',
      get: function get() {
        if (this._mesh) {
          return this._mesh;
        }

        var geometry = this.geometry;
        var material = this.material;
        this._mesh = new _npmThree['default'].Mesh(geometry, material);

        return this._mesh;
      }
    }, {
      key: 'material',
      get: function get() {
        if (this._material) {
          return this._material;
        }

        this._material = new _npmThree['default'].ShaderMaterial({
          vertexShader: this.vertexShader,
          fragmentShader: this.fragmentShader,
          uniforms: this.uniforms
        });

        return this._material;
      }
    }, {
      key: 'uniforms',
      get: function get() {
        return {
          uTexture: { type: 't', value: this.texture },
          uPartyMode: { type: 'i', value: 0 },
          uTime: { type: 'f', value: this.time }
        };
      }
    }, {
      key: 'geometry',
      get: function get() {
        console.warn('Subclasses must implement this method.');
      }
    }, {
      key: 'rotation',
      get: function get() {
        return this.mesh.rotation;
      }
    }, {
      key: 'time',
      get: function get() {
        if (!this._time) {
          this._time = 1.0;
        }
        this._time += this.clock.getDelta();
        return this._time;
      }
    }]);

    return _default;
  })();

  exports['default'] = _default;
});